import React from "react";
import { useToggle } from "@react-md/utils";

import Layout from "../components/Layout/Layout";
import Hero from "../components/ResetPassword/Hero";
import ResetPasswordForm from "../components/ResetPassword/ResetPasswordForm";

import "../styles/pages/reset_password.scss";
import "../styles/components/Layout/layout.scss";
import "../styles/pages/responsiveness.scss";
import { navigate } from "gatsby";

export default function ResetPassword(props) {
  if ((!props || !props.params["*"]) && typeof window !== "undefined")
    navigate("/"); // window check needed because window doesn't exist in build process
  // let id, token, teacher;
  let token;
  if (props && props.params["*"]) {
    token = props.params["*"];
    // const params = JSON.parse(props.params['*']);
    // id = params.id;
    // token = params.token;
    // teacher = params.teacher;
  }
  // const { id, token, teacher } = JSON.parse(props.params['*']);
  const [visible, enable, disable] = useToggle(false);
  setTimeout(() => {
    enable();
  }, 0);

  // if token is valid, show form to reset password. Otherwise, show meessage that password reset needs to be requested again.
  return (
    <Layout hideFooter={true}>
      <Hero enable={!disable} />
      <div className="spacer"></div>
      <ResetPasswordForm visible={visible} disable={disable} token={token} />
    </Layout>
  );
}
